<template>
  <div class="nav-content teal valign-wrapper">
    <img v-if="guild.icon" :src="discordCdn.guildIcon(guild, {size: 64})" alt="guild icon" class="circle left" id="guild-icon">
    <span class="nav-title">{{ guild.name }}</span>
    <button class="right btn-large btn-floating btn-flat"><i class="material-icons">cached</i></button>
    <a class="btn-floating btn-large halfway-fab waves-effect waves-light teal darken-3 modal-trigger" href="#create-ticket-modal">
      <i class="material-icons">add</i>
    </a>
  </div>
</template>

<script>
import M from "materialize-css";
import { discordDb, discordCdn } from "@/app/shared/services";

export default {
  name: "ExtendedNav",
  props: ["id"],
  data() {
    return {
      discordCdn,
      guild: {},
    }
  },
  created() {
    discordDb.getGuild(this.id).then(guild => this.guild = guild);
  },
  mounted() {
    M.AutoInit();
  }
}
</script>

<style scoped>
#guild-icon {
  margin: 0 1em;
}
</style>
